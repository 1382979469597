import React, { useEffect, useState } from 'react'
import aiImg from './../image/ai.png';
import mlImg from './../image/ml.png';
import swImg from './../image/sw.png';
import { FormattedMessage } from 'react-intl';
import "../index.css"
import { ReactComponent as DashIcon } from './../Icons/dash.svg'


export default function Carousel() {
    const aiContent = <>
        <img
            src={aiImg}
            className="w-full object-cover max-[1200px]:object-right h-full"
            alt="Image_by_iuriimotov"
            href="https://www.freepik.com/free-vector/wireframe-robot-ai-artificial-intelligence-robotic-hand-machine-learning-cyber-mind-domination-concept_8800979.htm#query=machine%20learning&position=46&from_view=search&track=sph"
        />

        <div className="absolute top-0  bottom-0  flex items-end   w-2/5 max-[1200px]:w-2/3 max-md:w-full bg-gradient-to-r from-black to-transparent">
            <div className="flex flex-col justify-center ml-20 mr-44  max-[670px]:m-auto  w-80 max-[600px]:w-5/6  mb-24 ">
                <h1 className="text-7xl max-[1440px]:text-5xl max-[1200px]:text-4xl   font-bold text-white">
                    <FormattedMessage id="app.carousel.ai.title" />
                </h1>
                <p className="pt-10 max-[550px]:pt-2 max-[1200px]:pt-4 leading-8 text-white max-[1400px]:text-sm  ">
                    <FormattedMessage id="app.carousel.ai.content" />
                </p>
            </div>
        </div>
    </>

    const mlContent = <>
        <img
            src={mlImg}
            className="w-full object-cover max-[1200px]:object-right h-full"
            alt="Freepik"
            href="https://www.freepik.com/free-vector/wireframe-robot-ai-artificial-intelligence-robotic-hand-machine-learning-cyber-mind-domination-concept_8800979.htm#query=machine%20learning&position=46&from_view=search&track=sph"
        />
        <div className="absolute top-0  bottom-0  flex items-end   w-2/5 max-[1200px]:w-2/3 max-md:w-full bg-gradient-to-r from-black to-transparent">
            <div className="flex flex-col justify-center ml-20 mr-44  w-80 max-[600px]:w-5/6 max-[670px]:m-auto mb-24 ">
                <h1 className="text-7xl max-[1440px]:text-5xl max-[1200px]:text-4xl   font-bold text-white">
                    <FormattedMessage id="app.carousel.ml.title" />
                </h1>
                <p className="pt-10  max-[1200px]:pt-4 leading-8 text-white max-[1400px]:text-sm  ">
                    <FormattedMessage id="app.carousel.ml.content" />
                </p>
            </div>
        </div>
    </>

    const swContent = <>
        <img
            src={swImg}
            className="w-full object-cover max-[1200px]:object-right h-full"
            alt="Image_by_lucabravo"
            href="https://www.freepik.com/free-photo/turned-gray-laptop-computer_12661377.htm#query=coding&position=1&from_view=search&track=sph"
        />
        <div className="absolute top-0  bottom-0  flex items-end   w-2/5 max-[1200px]:w-2/3 max-md:w-full bg-gradient-to-r from-black to-transparent">
            <div className="flex flex-col justify-center ml-20 mr-44  w-80 max-[600px]:w-5/6 max-[670px]:m-auto mb-24 ">
                <h1 className="text-7xl   max-[1440px]:text-5xl max-[1200px]:text-4xl   font-bold text-white">
                    <FormattedMessage id="app.carousel.imgProcess.title" />
                </h1>
                <p className="pt-10 max-[550px]:pt-2 max-[1200px]:pt-4  leading-8 text-white max-[1400px]:text-sm  ">
                    <FormattedMessage id="app.carousel.imgProcess.content" />
                </p>
            </div>
        </div>
    </>

    const slides = [aiContent, mlContent, swContent]

    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {


        let timer1 = setTimeout(() => {
            setCurrentIndex(value => value === slides.length - 1 ? 0 : value + 1)

        }, 3000)
        return () => {
            clearTimeout(timer1);
        };

    }, [currentIndex])// eslint-disable-line


    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    return (
        // <div className="w-full h-full">

        <div className="relative max-[1200px]:h-[32rem] bg-black">
            <div className=" relative w-full h-full overflow-hidden  ">
                <div className=" w-full h-full">
                    {slides.map((el, i) => {
                        let n
                        if (i === currentIndex) n = "transition-all  transform translate-x-0";
                        else if (i === currentIndex - 1) n = "transition-transform  transform absolute -translate-x-full";
                        else if (i === slides.length - 1 && currentIndex === 0) n = "transition-transform transform absolute -translate-x-full"
                        else n = " transition-none  transform absolute translate-x-full"

                        return <div key={i}
                            className={` ${n} duration-1000 float-left w-full h-full  slide`}>
                            {el}
                        </div>
                    }
                    )}

                    <div className='flex bottom-0 justify-center py-2  w-full absolute' >
                        {slides.map((slide, slideIndex) => (
                            <div
                                key={slideIndex}
                                onClick={() => goToSlide(slideIndex)}
                                className='text-2xl cursor-pointer '
                            >
                                <DashIcon fill={slideIndex === currentIndex ? "#fff" : "#888"} />

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
