import React, { useContext } from 'react';
import logo from './../image/Logo.png';
import { FormattedMessage } from 'react-intl';
import { ThemeContext } from '../Context/ThemeContext';

export default function NavBar({ refSelected }) {
  const { updateLang } = useContext(ThemeContext);
  return (
    <nav className="fixed top-0  absolute w-full flex flex-wrap items-center justify-between py-4 max-sm:py-1 bg-nav-blue text-gray-200 shadow-lg navbar navbar-expand-lg navbar-light z-50">
      <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
        <div className=" flex-grow items-center ml-10">
          <img
            src={logo}
            alt="Artemyth"
            className="w-16 max-[1000px]:w-12 max-[670px]:w-10"
          />
        </div>

        <div className="flex items-center relative mr-5  w-2/5 justify-end ">
          <ul
            className="navbar-nav flex flex-col pl-0 list-style-none mr-auto collapse navbar-collapse justify-around w-full "
            id="navbarSupportedContent1">
            <li className="nav-item p-2  " onClick={() => refSelected(0)}>
              <p className="nav-link text-white opacity-80 hover:opacity-100  p-0 text-xl max-[1000px]:text-lg">
                <FormattedMessage id="app.nav.products" />
              </p>
            </li>
            <li className="nav-item p-2" onClick={() => refSelected(1)}>
              <p className="nav-link text-white opacity-80 hover:opacity-100  p-0 text-xl max-[1000px]:text-lg">
                <FormattedMessage id="app.nav.experiences" />
              </p>
            </li>
            <li className="nav-item p-2" onClick={() => refSelected(2)}>
              <p className="nav-link text-white opacity-80 hover:opacity-100  p-0 text-xl max-[1000px]:text-lg">
                <FormattedMessage id="app.nav.contact" />
              </p>
            </li>
            <li className="nav-item p-2">
              <div className="flex justify-center">
                <div className="xl:w-24">
                  <select
                    defaultValue={'th'}
                    onChange={(e) => updateLang(e.target.value)}
                    className=" appearance-none
      
      px-1.5
      text-center
      text-xl
      text-white
      bg-transparent  bg-no-repeat
      transition
      ease-in-out
      m-0
       "
                    aria-label="Default select example">
                    <option value="th">TH</option>
                    <option value="en">EN</option>
                  </select>
                </div>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler text-gray-200 border-0 hover:shadow-none hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent"
            aria-expanded="true"
            aria-label="Toggle navigation">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="bars"
              className="w-6"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512">
              <path
                fill="currentColor"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
            </svg>
          </button>
        </div>
      </div>
    </nav>
  );
}
