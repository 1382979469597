import React, { useState, useRef } from 'react';
import Carousel from '../Components/Carousel';
import ProductCard from '../Components/ProductCard';
import { FormattedMessage } from 'react-intl';
import aiIcon from './../image/icons8-artificial-intelligence-96.png';
import robotIcon from './../image/icons8-robotic-arm-96.png';
import softwareIcon from './../image/icons8-source-code-96.png';
import Content from '../Components/Content';
import ciraAi from './../image/cira.png';
import robot from './../image/robot.png';
import software from './../image/software.png';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';



export default function Home() {
  const refProducts = useRef();
  const refExperiences = useRef();
  const refContact = useRef();
  const ref = [refProducts, refExperiences, refContact];

  const handleClick = (index) => {

    ref[index].current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCardSelected = (index) => {
    setProduct(cards[index]);
    ref[1].current?.scrollIntoView({ behavior: 'smooth' });
  };

  const cards = [
    {
      icon: aiIcon,
      iconAlt: 'Artificial Intelligence icon by Icons8',
      title: <FormattedMessage id="app.card.title.ai" />,
      itemList: <FormattedMessage id="app.card.itemList.ai" />,
      content: <FormattedMessage id="app.card.content.ai" />,
      tabTarget: '#tabs-ai',
      component: (
        <Content
          content={<FormattedMessage id="app.aiContent.content" />}
          picture={ciraAi}
          youTubeList={[
            {
              url: 'https://www.youtube.com/embed/jf9gASNfkO8',
              title: 'CiRA CORE',
              subTitle: 'Basler Camera integration'
            },
            {
              url: 'https://www.youtube.com/embed/iY4Pw2TknBU',
              title: 'CiRA CORE',
              subTitle: 'Camera Distorsion Calibration'
            },
            {
              url: 'https://www.youtube.com/embed/fV6sD4JZEVg',
              title: 'CiRA CORE',
              subTitle: 'PLC Plugin (Modbus I/O Module)'
            },
            {
              url: 'https://www.youtube.com/embed/k0iEekf7cGw',
              title: 'CiRA CORE',
              subTitle: 'Deep Detect with PLC Mitsubishi'
            },
            {
              url: 'https://www.youtube.com/embed/X4u-BUFxxLs',
              title: 'CiRA CORE',
              subTitle: 'Siemens SIMATIC IoT2040 Integrated'
            }
          ]}
          listTitle={[
            <FormattedMessage id="app.aiContent.Platform.list.title" />,
            <FormattedMessage id="app.aiContent.Plugin.list.title" />
          ]}
        />
      )
    },
    {
      icon: robotIcon,
      iconAlt: 'Robotic Arm icon by Icons8',
      title: <FormattedMessage id="app.card.title.robot" />,
      itemList: <FormattedMessage id="app.card.itemList.robot" />,
      content: <FormattedMessage id="app.card.content.robot" />,
      tabTarget: '#tabs-robot',
      component: (
        <Content
          content={<FormattedMessage id="app.robotContent.content" />}
          picture={robot}
          youTubeList={[
            {
              url: 'https://www.youtube.com/embed/d_dShD0P8Jc',
              title: 'ROS',
              subTitle: 'Inverse Kinematics'
            }
          ]}
          listTitle={[<FormattedMessage id="app.robotContent.list.title" />]}
        />
      )
    },
    {
      icon: softwareIcon,
      iconAlt: 'Source Code icon by Icons8',
      title: <FormattedMessage id="app.card.title.software" />,
      itemList: <FormattedMessage id="app.card.itemList.software" />,
      content: <FormattedMessage id="app.card.content.software" />,
      tabTarget: '#tabs-software',
      component: (
        <Content
          content={<FormattedMessage id="app.softwareContent.content" />}
          picture={software}
          listTitle={[
            <FormattedMessage id="app.softwareContent.c++.list.title" />,
            <FormattedMessage id="app.softwareContent.c#.list.title" />,
            <FormattedMessage id="app.softwareContent.py.list.title" />
          ]}
        />
      )
    }
  ];

  const [product, setProduct] = useState(cards[0]);

  // console.log(refProducts);
  return (
    <div className="Home bg-page-background  w-full">
      <NavBar refSelected={handleClick} />
      <div className="herosection mb-4 ">
        <Carousel />
      </div>

      <div
        ref={refProducts}
        className="container  mx-auto pt-20 pb-10  max-[1300px]:px-12 max-[450px]:px-2 mt-10"
      >
        <h1 className="text-center text-4xl font-bold"

        >
          {<FormattedMessage id="app.home.title.products" />}
        </h1>
        <div className="flex justify-center xl:justify-end">
          {/* <div className="grid  auto-cols-min grid-cols-3 grid-rows-3 bg-orange-400"> */}

          {/* <div className="flex w-full justify-between  flex-wrap"> */}
          <ul
            className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4 justify-between w-full max-xl:justify-center max-md:items-center"
            id="tabs-tab"
            role="tablist">
            {cards.map((card, index) => (
              <li
                key={index}
                className="nav-item flex"
                role="presentation"
                onClick={() => handleCardSelected(index)}>
                <div
                  className="product-card max flex 
                            nav-link
                            text-black
                            border-x-0 border-t-0 border-b-8 border-transparent
                            py-3
                            focus:border-transparent
                            aria-selected:text-black"
                  id="tabs-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target={card.tabTarget}
                  role="tab"
                  aria-controls={card.tabTarget.substring(1)}
                  aria-selected="true">
                  <ProductCard
                    icon={card.icon}
                    iconAlt={card.iconAlt}
                    title={card.title}
                    itemList={card.itemList}
                    content={card.content}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div ref={refExperiences}>
          <h1 className="text-center text-4xl font-bold pt-20">
            {<FormattedMessage id="app.home.title.experiences" />}
          </h1>
          <h2 className="text-center m-5 text-2xl font-bold">
            {product.title}
          </h2>
        </div>
        <div className="tab-content" id="tabs-tabContent">
          <div
            className="tab-pane fade show active"
            id="tabs-ai"
            role="tabpanel">
            {product.component}
          </div>
        </div>
      </div>
      <div ref={refContact}>
        <Footer />
      </div>
    </div>
  );
}
