import React, { createContext, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [lang, setlang] = useState('th');

  const updateLang = (lang) => {
    setlang(lang);
  };

  return (
    <ThemeContext.Provider value={{ lang, updateLang }}>
      {children}
    </ThemeContext.Provider>
  );
};
