import React from 'react';

export default function Content({ listTitle, youTubeList, content, picture }) {
  return (
    <div className="mainContent mt-10 mx-4 ">
      <div className="flex w-full justify-between flex-wrap max-lg:flex-col ">
        <div className="w-2/5 max-xl:w1/2 max-lg:w-full max-lg:mb-12">
          <div>
            <ul className="list-disc px-4 ">
              {listTitle &&
                listTitle.map((title, index) => (
                  <li
                    key={index}
                    className="whitespace-pre-line font-bold my-1.5 ">
                    {title}
                  </li>
                ))}
            </ul>
          </div>
          <div className="mt-5">
            {content}
            {/* {<FormattedMessage id="app.aiContent.content" />} */}
          </div>
        </div>

        <div className="w-3/5 flex items-start justify-end max-xl:w-1/2 max-lg:w-full max-lg:mb-12">
          <img src={picture} alt="CiRA Program" className="border-none"></img>
        </div>
      </div>

      <div className="grid my-16 grid-cols-2 gap-20 max-lg:grid-cols-1 ">
        {youTubeList &&
          youTubeList.map((list, index) => (
            <div className="w-full h-full" key={index}>
              <h3 className="my-4">
                <span className="text-xl font-bold">{list.title} : </span>
                <span className="text-xl">{list.subTitle}</span>
              </h3>

              <iframe
                className="video w-full flex-grow"
                width="560"
                height="315"
                src={list.url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ))}
      </div>
    </div>
  );
}
