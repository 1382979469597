import React from 'react';
import facebook from './../image/facebook.png';
import location from './../image/location.png';
import email from './../image/email.png';
import phone from './../image/phone.png';
import Logo from './../image/Logo.png';
import { FormattedMessage } from 'react-intl';

export default function Footer() {
  return (
    <footer className="bg-footer-background text-white flex flex-wrap p-12 justify-around relative max-[670px]:flex-col-reverse max-[670px]:items-center">
      <div className="w-2/5 flex justify-center items-center max-[670px]:my-12 ">
        <img src={Logo} alt="Artemyth" className="w-3/5 " />
      </div>
      <div className="w-2/5 max-lg:w-3/5 max-[600px]:w-full">
        <div className="flex w-full items-start max-[600px]:justify-center">
          <img
            className="w-12 mr-12 h-12 max-[450px]:mr-4"
            src={location}
            alt="Location icon by Icons8"
          />
          <div className="flex flex-col w-1/2 max-xl:w-3/5 max-lg:w-full  ">
            <h4 className="text-2xl font-bold max-[500px]:text-lg">
              <FormattedMessage id="app.footer.companyName" />
            </h4>
            <p className="max-[500px]:text-sm whitespace-pre-line">
              <FormattedMessage id="app.footer.address" />
            </p>
          </div>
        </div>
        <div className="flex w-full items-center mt-8 max-[670px]:mt-3">
          <img
            className="w-12 mr-12 max-[450px]:mr-4"
            src={facebook}
            alt="Facebook icon by Icons8"
            onClick={() =>
              window.open('https://www.facebook.com/Artemyth-103142865373194/')
            }
          />
          <h4
            className="text-xl font-bold max-[500px]:text-lg"
            onClick={() =>
              window.open('https://www.facebook.com/Artemyth-103142865373194/')
            }>
            Artemyth
          </h4>
        </div>

        <div className="flex w-full items-center mt-8 max-[670px]:mt-3">
          <img
            className="w-12 mr-12 max-[450px]:mr-4"
            src={email}
            alt="Phone icon by Icons8"
            // alt="Circled Envelope icon by Icons8"
            href="https://icons8.com/icon/35084/circled-envelope"
          />
          <h4 className="text-xl font-bold max-[500px]:text-lg">
            <FormattedMessage id={'app.footer.email'} />
          </h4>
        </div>
        <div className="flex w-full items-center mt-8 max-[670px]:mt-3">
          <img
            className="w-10 ml-1 mr-12 max-[450px]:mr-4"
            src={phone}
            alt="Phone icon by Icons8"
          />
          <h4 className="text-xl font-bold max-[500px]:text-lg">
            <FormattedMessage id={'app.footer.phone'} />
          </h4>
        </div>
      </div>
      <p className="text-sm text-white absolute bottom-0 left-0 text-center w-full mb-4">
        © 2022 Artemyth
      </p>
    </footer>
  );
}

// Phone icon by Icons8

//  {/* <a
//             target="_blank"
//             href="https://icons8.com/icon/35084/circled-envelope">
//             Circled Envelope
//           </a>{' '}
//           icon by{' '}
//           <a target="_blank" href="https://icons8.com">
//             Icons8
//           </a> */}
