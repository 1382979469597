import Home from './Page/Home';
import { IntlProvider } from 'react-intl';
import th from './i18n/th.json';
import en from './i18n/en.json';
import { ThemeContext } from './Context/ThemeContext';
import { useContext, useEffect, useState } from 'react';

function App() {
  const { lang } = useContext(ThemeContext);
  const [messages, setMessage] = useState(th);
  useEffect(() => {
    if (lang === 'th') {
      setMessage(th);
    } else {
      setMessage(en);
    }
  }, [lang]);

  return (
    <IntlProvider messages={messages} locale={lang}>
      <div className="App">
        <Home />
      </div>
    </IntlProvider>
  );
}

export default App;
