import React from 'react';
// import aiIcon from './../image/icons8-artificial-intelligence-96.png'
// import robotIcon form

export default function ProductCard({
  title,
  itemList,
  content,
  icon,
  iconAlt
}) {
  return (
    <div className="w-340  flex flex-col items-center hover:drop-shadow-plus-card-hover max-xl:mx-12 max-xl:my-8 max-md:mx-0">
      <div className=" p-5 flex justify-center items-center drop-shadow-plus-icon drop-shadow-minus-icon">
        <img
          src={icon}
          alt={iconAlt}
          className="rounded-full  p-3 bg-slate-200"
        />
      </div>
      <div
        className="p-4 bg-card-background rounded-xl flex-grow "
        id="content">
        <div>
          <h1 className="text-2xl font-bold text-left my-2 whitespace-pre">
            {title}
          </h1>
          <div className="px-4">
            <ul className="list-disc">
              <li className="whitespace-pre font-bold my-1.5">{itemList}</li>
            </ul>
          </div>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
}
